<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <div class="fill-width d-flex justify-space-between flex-wrap">
                    <div class="mr-2"><v-btn text :to="{name: 'admin.story-reports.index'}">return</v-btn></div>
                </div>
            </v-card-title>
            <v-card-text>
                <v-form v-if="report">
                    <v-row>
                        <v-col>
                            <v-text-field :value="report.blocked_user.id" label="Reporter ID" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="report.blocked_user.name" label="Reporter" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea :value="report.accusation" label="Accusation" disabled></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="report.story_with_blocked.creator.blocked_user.id" label="Dependant ID" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="report.story_with_blocked.creator.blocked_user.name" label="Dependant" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="report.story_with_blocked.overlayUrl">
                        <v-col cols="6" v-if="report.story_with_blocked.is_video">
                            <h3>Story Video</h3>
                            <div class="mt-6" id="plyr-wrapper">
                                <video ref="video" controls playsinline class="video" :src="videoUrl" />
                            </div>
                        </v-col>
                        <v-col cols="6" v-else>
                            <h3>Story Image</h3>
                            <div class="mt-6">
                                <v-img class="story-img" :src="report.story_with_blocked.overlayUrl" />
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-if="report.story_with_blocked.blocked_at">
                        <v-col>
                            <v-text-field :value="moment(report.story_with_blocked.blocked_at).format('LLL')" label="Blocked" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="moment(report.created_at).format('LLL')" label="Created" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn v-if="report.story_with_blocked.blocked_at" class="error" @click="openDialog">Unblock Story</v-btn>
                            <v-btn v-else class="error" @click="openDialog">Block Story</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <Dialog @no="closeDialog" @yes="handleBlock" v-if="dialog">{{
                $t('dialogs.are_you_sure')
            }}
        </Dialog>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
    import StoryReport from "@/models/admin/StoryReport";
    import Dialog from "@/components/app/setting/YesNoDialog";
    import {VIDEO_MP4_EXTENSION, VIDEO_WEBM_EXTENSION} from "@/configs/constants";
    import {isSafari} from "@/helpers/functions";
    import {mapActions, mapMutations} from "vuex";

    export default {
        name: "story-reports-show",
        components: {
            AdminDashboardLayout,
            Dialog,
        },
        data: function () {
            return {
                report: false,
                player: {},
                errors: {},
                dialog: false,
            }
        },
        computed: {
            videoMp4() {
                return `${this.report.story_with_blocked.overlayUrl}.${VIDEO_MP4_EXTENSION}`
            },
            videoWebm() {
                return `${this.report.story_with_blocked.overlayUrl}.${VIDEO_WEBM_EXTENSION}`
            },
            videoUrl() {
                return isSafari() ? this.videoMp4 : this.videoWebm
            },
        },
        methods: {
            ...mapMutations(['showSnackBar']),
            ...mapActions([
                'AdminBlockStory',
            ]),
            async handleBlock() {
                this.closeDialog()
                this.AdminBlockStory(this.report.story_with_blocked.id).then(async () => {
                    this.report = await StoryReport.include([
                        'blockedUser',
                        'storyWithBlocked',
                        'storyWithBlocked.creator.blockedUser'
                    ]).find(this.$route.params.id)
                })
            },
            openDialog() {
                this.dialog = true
            },
            closeDialog() {
                this.dialog = false
            },
        },
        async mounted() {
            const reportId = this.$route.params.id
            this.report = await StoryReport.include([
                'blockedUser',
                'storyWithBlocked',
                'storyWithBlocked.creator.blockedUser'
            ]).find(reportId)
        },
    }
</script>

<style scoped>

</style>
